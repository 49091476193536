import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";

import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";



//import MediaCard from "./components/future/mediacard"

import { green } from '@mui/material/colors';
import Crous2 from "./components/Crous2";
import Chooseus from "./components/chooseus";


import Certifications from "./components/Certifications";
import { Servicelink2 } from "./components/servicelink2";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

{/*
const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};
 const fabGreenStyle = {
    color: 'common.white',
    bgcolor: green[500],
    '&:hover': {
      bgcolor: green[600],
    },
  };

*/}


const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const canonicalUrl = "http://sarthakjeevannasamuktikendra.in/";

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <title>Sarthak Jeevan Nasa Mukti Kendra in Patna @ 9044605877 | De-Addiction & Rehabilitation Center</title>
        <meta name="keywords" content="Nasa Mukti Kenda, Nasa Mukti Kenda in Patna, Nasa Mukti Kendra in Bihar, Nasa Mukti Kendra Nearby, Nasa Mukti Kendra Near me, Best Nasa Mukti Kenda in Patna, Best Nasa Mukti Kendra in Bihar, Best Nasa Mukti Kendra Nearby, Best Nasa Mukti Kendra Near me, rehabilitation centre, rehabilitation centre in Patna" />
        <meta name="keywords" content="rehabilitation centre in Bihar, rehabilitation centre nearby, rehabilitation centre near me, nasha mukti kendra number, patna nasha mukti kendra number, bihar nasha mukti kendra number, best nasha mukti kendra number, near me nasha mukti kendra, near me best nasha mukti kendra, near me nasha mukti kendra in patna, near me nasha mukti kendra in Bihar, Sarthak Jeevan nasha mukti kendra, nasha mukti kendra kahan hai, patna mai nasha mukti kendra kahan hai, bihar mai nasha mukti kendra kahan hai, rehab near me, best rehab near me, rehab in patna, rehab in patna nearby, rehab in patna near me" />
        <meta name="keywords" content={`नशा मुक्ति केंद्र, नशा मुक्ति केंद्र बिहार, नशा मुक्ति केंद्र पटना, सार्थक जीवन नशा मुक्ति केंद्र`} />
        <meta name="author" content="Sarthak jeevan nasamukti kendra " />
        <meta name="description" content="Nasamukti kendra Patna: We are Best Drug De-addiction & Alcohol Rehabilitation Centre in Patna offering best of the Deaddiction Treatment Programs in Patna, Bihar." ></meta>
      </Helmet>

      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Features data={landingPageData.Features} />
      <Certifications data={landingPageData.Therapy} />
      <Team data={landingPageData.Team} />


      {/*<Crous /> 
        <Crous2 /> 
        <Caurousel />
        <Gallery data={landingPageData.Gallery}/>
        <Header data={landingPageData.Header} />
        for whatspp web use link href="https://wa.me/9060591201"

       
      
           
        <Servicelink2 data={landingPageData.Services} />
      */}







      <Contact data={landingPageData.Contact} />

      <Testimonials data={landingPageData.Testimonials} />
    </div>
  );

}


export default Home;

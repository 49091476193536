import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import emailjs from 'emailjs-com'
import React, { useState, useEffect, useRef } from 'react';
import { Brandlink } from "../../brandlink";
import Certifications from "../../Certifications";
import JsonData from "../../../data/data.json";
import { FaqCommon } from "../../faqcommon";


const initialState = {
    from_name: '',
    mobile: '',
    adress: '',
}
export const Heroin = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);

    const articleSchema = {
        "@context": "https://schema.org",
        "@type": "Article",
        "headline": "Acohol Addiction Treatment",
        "author": {
            "@type": "Person",
            "name": "Wappotix"
        },
        "datePublished": "2024-12-01",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "http://www.sarthakjeevannasamuktikendra.in/"
        },
        "articleBody": "sarthak jeevan nasa muktikendra is the Best Drug De-addiction & Alcohol Rehabilitation Centre in Patna offering best of the Deaddiction Treatment Programs in Patna, Bihar."
    };

    const canonicalUrl = "http://sarthakjeevannasamuktikendra.in/heroin-addiction-treatment-patna";

    const [{ from_name, email, mobile, adress }, setState] = useState(initialState)
    const formRef = useRef();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target
        setState((prevState) => ({ ...prevState, [name]: value }))
    }
    const clearState = () => setState({ ...initialState })

    const validateMobile = (mobile) => {
        const regex = /^\d{10}$/; // Regex for 10 digit mobile number
        return regex.test(mobile);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateMobile(mobile)) {
            alert("Please enter a valid 10-digit mobile number.");
            return;
        }
        console.log(from_name, email, mobile, adress)
        emailjs
            .sendForm(
                'service_jhvavwm', 'template_37fc66s', e.target, 'ApOnybWaQRwdj7Zxt'
            )
            .then(
                (result) => {
                    alert('Your Call Back Request has been submitted , you will get a get from our team within 5-10 min. Thanks for your patience')
                    clearState();
                    formRef.current.reset();
                    navigate("/");
                },
                (error) => {
                    console.log(error.text)
                }
            )
    }
    return (
        <>
            <div id="differntlocation">
                <Helmet>
                    <link rel="canonical" href={canonicalUrl} />
                    <title>Sarthak Jeevan Nasa Mukti Kendra in Patna @ 9044605877 | Heroin Addiction Treatment</title>
                    <meta name="keywords" content="Heroin Addiction Treatment, Heroin Addiction Treatment in Patna, Heroin Addiction Treatment in Bihar, Best Heroin Addiction Treatment in Patna, Best Heroin Addiction Treatment in Bihar" />
                    <meta name="keywords" content="Best Heroin Addiction Treatment, Heroin Addiction Treatment in Patna, Heroin Addiction Treatment in Bihar, Best Heroin Addiction Treatment in Patna, Best Heroin Addiction Treatment in Bihar" />
                    <meta name="keywords" content=" Heroin Addiction Treatment in Rehabilitation center, Heroin Addiction Treatment in rehab Patna" />
                    <meta name="author" content="Sarthak jeevan nasamukti kendra " />
                    <meta name="description" content={`Nasamukti kendra Patna: We are Best Drug De-addiction & Alcohol Rehabilitation Centre in Patna offering best of the Deaddiction Treatment Programs in Patna, Bihar.`} ></meta>
                </Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(articleSchema)}
                </script>
                <div className="navigation-wrapper">
                    <div className="Container fluid ">
                        <header className="location-header" >
                            <div className="container h-100">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="text-center align-items-center">
                                            <div className="w-100 text-onbanner">
                                                <h1 className="display-3"> Heroin Rehabilitation Centre in Patna | Sarthak Jeevan Nasa Mukti Kendra @9044605877</h1>
                                            </div>
                                            <div className="call-action-area">
                                                <span className="number"><a href="tel:9044605877 " style={{ fontWeight: 'bold' }}><i className="fa fa-phone"></i>  +91-9044605877 </a></span>
                                                <span className="number"><a href="https://api.whatsapp.com/send?phone=919044605877" target="_blank" style={{ fontWeight: 'bold' }}><i className="fa fa-whatsapp" style={{ backgroundColor: '#25D366' }}></i>  +91-9044605877 </a></span>
                                                {/*<span className="request"><a data-toggle="modal" data-target="#newModalForWizard">Request a Call Back</a></span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 locform">
                                        <div className='row'>
                                            <div className='section-title'>
                                                <h2>SUBMIT A CALL BACK REQUEST</h2>
                                                <p>
                                                    Please fill out the form below to send us an email and we will
                                                    get back to you as soon as possible.
                                                </p>
                                            </div>
                                            <form ref={formRef} name='sentMessage' onSubmit={handleSubmit}>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className='form-group'>
                                                            <input
                                                                type='text'
                                                                id='name'
                                                                name='from_name'
                                                                className='form-control'
                                                                placeholder='Full Name*'
                                                                required
                                                                onChange={handleChange}
                                                            />
                                                            <p className='help-block text-danger'></p>
                                                            <input
                                                                type='email'
                                                                id='email'
                                                                name='email'
                                                                className='form-control'
                                                                placeholder='Email'
                                                                onChange={handleChange}
                                                            />
                                                            <p className='help-block text-danger'></p>
                                                            <input
                                                                type='number'
                                                                id='mobile'
                                                                name='mobile'
                                                                className='form-control'
                                                                placeholder='Mobile Number*'
                                                                required
                                                                onChange={handleChange}
                                                                maxLength={10}
                                                                minLength={10}

                                                            />
                                                            <p className='help-block text-danger'></p>
                                                            <div className='form-group'>
                                                                <textarea
                                                                    name='adress'
                                                                    id='adress'
                                                                    className='form-control'
                                                                    rows='3'
                                                                    placeholder='Address'

                                                                    onChange={handleChange}
                                                                ></textarea>
                                                                <p className='help-block text-danger'></p>
                                                            </div>
                                                            <div id='success'></div>

                                                        </div>
                                                    </div>


                                                </div>

                                                <div id='success'></div>
                                                <button type='submit' className='btn-customcolor' style={{ marginLeft: '20px' }}>
                                                    Get a Call Back From Us
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>

                        <section id="Aqufresh-content-area ">
                            <div className="container">
                                <div className="row row-reverse">

                                    <div className="common-section brandss-section-here" >
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="content-area-">
                                                    <h2><strong>Sarthak Jeevan Nasamukti Kendra in Patna</strong></h2>

                                                    <p>Welcome to <a href="/"><strong>Sarthak Jeevan Nasha Mukti Kendra </strong></a>&nbsp;in Patna a trusted and compassionate center dedicated to helping individuals break free from addiction and rediscover their purpose in life. </p>

                                                    <p>Welcome to we understand that addiction is not just a physical condition but a complex challenge that affects the mind, body, and soul.&nbsp;Our mission is to provide a safe, supportive, and non-judgmental environment where individuals can heal and transform their lives.</p>

                                                    <p><strong>Nasamukti kendra | Nasamukti kendra Center Number&nbsp; 9044605877</strong></p>

                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-responsive">
                                                            <thead>
                                                                <tr className="table-success">
                                                                    <td><strong>#</strong></td>
                                                                    <td><strong>Nasamukti kendra Center</strong></td>
                                                                    <td><strong>Contact Number</strong></td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr className="table-success">
                                                                    <td><strong>1</strong></td>
                                                                    <td><a href="/alcohol-addiction-treatment-patna">Alcohol Nasamukti kendra&nbsp;Patna</a></td>
                                                                    <td><a href="tel: 9044605877">9044605877</a></td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td><strong>2</strong></td>
                                                                    <td><a href="/drug-addiction-treatment-patna">Drugs Nasamukti kendra&nbsp;Patna</a></td>
                                                                    <td><a href="tel:9044605877">9044605877</a></td>
                                                                </tr>
                                                                <tr className="table-success">
                                                                    <td><strong>3</strong></td>
                                                                    <td><a href="/cocaine-addiction-treatment-patna">Cocine Nasamukti kendra Near Me&nbsp;Patna</a></td>
                                                                    <td><a href="tel:9044605877">9044605877</a></td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td><strong>4</strong></td>
                                                                    <td><a href="/marijuana-addiction-treatment-patna">marijuana Nasamukti kendra&nbsp;Patna</a></td>
                                                                    <td><a href="tel:9044605877">9044605877</a></td>
                                                                </tr>
                                                                <tr className="table-success">
                                                                    <td><strong>5</strong></td>
                                                                    <td><a href="/whitner-addiction-treatment-patna">Whitner Nasamukti kendra&nbsp;Patna</a></td>
                                                                    <td><a href="tel:9044605877">9044605877</a></td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td><strong>6</strong></td>
                                                                    <td><a href="/brownsugar-addiction-treatment-patna">Brown Sugar Nasamukti kendra&nbsp;Patna</a></td>
                                                                    <td><a href="tel:9044605877">9044605877</a></td>
                                                                </tr>
                                                                <tr className="table-success">
                                                                    <td><strong>7</strong></td>
                                                                    <td><a href="/injection-addiction-treatment-patna">Injection Nasamukti kendra&nbsp;Patna</a></td>
                                                                    <td><a href="tel:9044605877">9044605877</a></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <h3><strong>Why Nasamukti kendra In Patna?</strong></h3>

                                                    <p>The Nasamukti kendras in Patna that you get from Sarthak jeevan nasamukti kendra never compromise your health. We hire always a well-experienced professional who has a good background and have worked with customers in the past ad well. Our team also checks their training, knowledge and also work process to ensure that they can maintain the quality of the services that we promise to offer to our Patients. Our&nbsp;<a href="/"><strong>Nasa Mukti kendras</strong></a>&nbsp;are always premium quality service for our customers and clients. We handle all kinds of Addition and ensure that they Quit it.</p>

                                                    <p>Sarthak jeevan nasamukti kendra is the latest and Best in the service industry where many customers get satisfied and happy with our services in Patna.</p>

                                                    <h2></h2><h2><strong>Why Sarthak Jeevan Nasa Mukti kendra is Best in Patna ?</strong></h2>

                                                    <p>The <strong><a href="/">Sarthak Jeevan Nasha Mukti Kendra </a></strong>&nbsp;in Patna is likely a rehabilitation center dedicated to helping individuals overcome addiction and lead a better life. The term <strong><a href="/">Sarthak Jeevan Nasha Mukti Kendra </a></strong> translates to "Addiction De-addiction Center," and these centers aim to treat people struggling with drug or alcohol addiction.</p>

                                                    <h2></h2><h3><strong>Reasons for Establishing Sarthak Jeevan Nasha Mukti Kendra:</strong></h3>

                                                    <ul>
                                                        <li><b><u>Addressing Addiction Issues:</u></b> Addiction to substances such as alcohol, drugs, and tobacco is a significant issue in India, including Patna, Bihar. Establishing a de-addiction center helps address the increasing number of individuals facing substance abuse problems.</li>
                                                        <li><b><u>Providing Rehabilitation:</u></b>  Such centers focus on offering rehabilitation and recovery programs that include counseling, detoxification, therapy, and emotional support to help individuals regain control over their lives and reduce the societal burden of addiction.</li>
                                                        <li><b><u>Holistic Approach:</u></b> Institutions like Sarthak Jeevan Nasha Mukti Kendra often adopt a holistic approach, combining medical treatments with psychological and emotional counseling, yoga, and other wellness practices to foster long-term recovery.</li>
                                                        <li><b><u>Raising Awareness:</u></b> In case of any malfunction or breakdown, water purifier service centres in Patna provide prompt repair services. Trained technicians diagnose the issue and undertake the necessary repairs using genuine spare parts to restore the purifier to its optimal working condition.</li>
                                                        <li><b><u>Supporting Families:</u></b> Aquapure Nasamukti kendra centres in Patna offer AMC plans wherein customers can opt for annual maintenance contracts for their water purifiers. These contracts typically include periodic maintenance visits, filter replacements, and priority service in case of emergencies, providing customers with peace of mind and hassle-free maintenance.</li>
                                                        <li><b><u>Community Impact:</u></b> Aquapure Nasamukti kendra centre also offers water quality testing services to assess the level of contamination in the water supply. Based on the test results, they can recommend suitable purification solutions or additional filtration systems to ensure the water is safe for consumption.</li>
                                                        <blockquote>
                                                            <p>In essence, <strong><a href="/">Sarthak Jeevan Nasha Mukti Kendra </a></strong> in Patna exists to help people affected by addiction regain their health, dignity, and quality of life, contributing to a healthier and more aware society.</p>
                                                        </blockquote>
                                                    </ul><br />

                                                    <h2></h2><h3><strong>Why is Sarthak Jeevan Nasa Mukti kendra the best de-addiction Treatment Center Patna ?</strong></h3>

                                                    <p>The <strong><a href="/">Sarthak Jeevan Nasha Mukti Kendra </a></strong> in Patna may be considered one of the best de-addiction centers in the region for several key reasons:</p>

                                                    <h4>1. Comprehensive Treatment Programs</h4>

                                                    <p>Sarthak Jeevan Nasha Mukti Kendra likely offers a <b>holistic approach</b> to addiction treatment. This involves not only detoxification but also personalized counseling, psychological therapy, group therapy, and medical treatment, addressing both the physical and emotional aspects of addiction.</p>

                                                    <h4>2. Experienced and Professional Staff</h4>

                                                    <p>The center probably has a team of <b>trained professionals</b>, including doctors, psychologists, counselors, and addiction specialists, who provide the best care for the individuals seeking rehabilitation. The expertise of the staff can significantly increase the chances of successful recovery.</p>

                                                    <h4>3. Focus on Long-Term Recovery</h4>

                                                    <p>Many rehabilitation centers focus on short-term recovery. However, Sarthak Jeevan Nasha Mukti Kendra might focus on <b>long-term recovery</b>, offering continued support after the detoxification process, ensuring that the individual does not relapse and can reintegrate into society healthily.</p>

                                                    <h4>4. Individualized Approach</h4>

                                                    <p>Every person’s battle with addiction is different. This center may provide <b>personalized treatment</b> plans that are tailored to the unique needs of each individual, taking into account their addiction history, mental health, and personal circumstances, which improves the likelihood of recovery.</p>

                                                    <h4>5. Support for Families</h4>

                                                    <p>The center may offer <b>family counseling</b> and support, which can help families understand the challenges of addiction, offer emotional support, and guide them on how to assist their loved ones in the recovery process. This creates a strong support network, which is critical for successful long-term recovery.</p>

                                                    <h4>6. Modern Facilities and Comfortable Environment</h4>

                                                    <p>Sarthak Jeevan Nasha Mukti Kendra might provide <b>state-of-the-art facilities</b>, including a peaceful and comfortable environment for individuals to focus on their rehabilitation. A serene and supportive setting can significantly aid the recovery process.</p>

                                                    <h4>7. Community Impact and Reputation</h4>

                                                    <p>The center's reputation in Patna could be built on its success stories, where individuals have successfully recovered and reintegrated into society. Positive testimonials and word-of-mouth recommendations from former patients and their families may contribute to its reputation as one of the best centers in the region.</p>

                                                    <h4>8. Confidentiality and Privacy</h4>

                                                    <p>Addiction treatment requires sensitivity, and the center is likely to maintain <b>high standards of confidentiality</b>, ensuring that patients’ personal information and treatment progress remain private and protected, which helps build trust with clients.</p>

                                                    <h4>9. Continuous Awareness Programs</h4>

                                                    <p>The center might be actively involved in <b>community outreach and awareness programs</b> in Patna. Educating the public about addiction, its dangers, and the treatment options available helps create an informed community, which can contribute to reducing stigma around addiction.</p>

                                                    <h4>10. High Success Rate</h4>

                                                    <p>With a well-established track record of helping people successfully overcome addiction, the Sarthak Jeevan Nasha Mukti Kendra may have a <b>high success rate</b> in treating individuals and helping them lead drug-free lives.</p>

                                                    <p>In summary, the <strong><a href="/">Sarthak Jeevan Nasha Mukti Kendra </a></strong>&nbsp;is likely regarded as one of the best centers in Patna due to its <b>comprehensive treatment approach, experienced staff, personalized care, family support, and long-term recovery focus</b>, which makes it stand out among other rehabilitation centers in the region.</p><br />


                                                    <h2></h2><h3><strong>Treatment and Therapy Provided at Sarthak Jeevan Nasa Mukti Kendra?</strong></h3>

                                                    <p>At Sarthak Jeevan Nasha Mukti Kendra, the treatment and therapy provided are likely designed to offer comprehensive support for individuals battling addiction. Although the specific therapies may vary, addiction treatment centers of this kind typically offer a range of evidence-based and holistic approaches to help individuals recover. Here’s a breakdown of the treatment and therapies that are commonly offered at such centers:</p>

                                                    <ol>
                                                        <li>
                                                            <h4>1. Detoxification (Detox)</h4>
                                                            <ul>
                                                                <li><b>. Medical Detox:</b> The first step in addiction treatment usually involves a medically supervised detox process. This helps remove the addictive substances from the body in a safe environment, often with the assistance of medications to ease withdrawal symptoms.</li>
                                                                <li><b>. Monitoring:</b> Patients are monitored by trained medical professionals to ensure their safety and comfort throughout the detoxification process.</li>
                                                            </ul>

                                                            <h4>2. Psychological Counseling</h4>
                                                            <ul>
                                                                <li><b>. Individual Counseling:</b> One-on-one sessions with professional counselors or therapists are crucial in understanding the root causes of addiction. These sessions help patients explore their emotional and psychological triggers for substance use.</li>
                                                                <li><b>. Cognitive Behavioral Therapy (CBT):</b> CBT is commonly used in addiction treatment to help individuals identify and change negative thought patterns and behaviors related to their addiction.</li>
                                                                <li><b>. Motivational Interviewing (MI):</b> MI is often used to strengthen an individual’s motivation to change, helping them to understand the reasons behind their addiction and the need for recovery.</li>
                                                            </ul>
                                                            <h4>3. Group Therapy</h4>
                                                            <ul>
                                                                <li><b>. Group Therapy Sessions:</b> These are conducted with multiple patients at once and provide a space for individuals to share their experiences, challenges, and successes. It fosters a sense of community and support.</li>
                                                                <li><b>. Peer Support:</b> Group therapy allows individuals to connect with others who are facing similar struggles, providing mutual support and encouragement.</li>
                                                            </ul>
                                                            <h4>4. Family Therapy and Counseling</h4>
                                                            <ul>
                                                                <li><b>. Family Counseling:</b> Addiction doesn’t only affect the individual; it impacts families as well. Family counseling helps the loved ones understand the addiction, how to support the individual in recovery, and rebuild relationships that may have been affected by addiction.</li>
                                                                <li><b>. Family Education:</b> Educating families about the nature of addiction and recovery processes is an essential part of the healing journey.</li>
                                                            </ul>
                                                            <h4>5. Yoga and Meditation</h4>
                                                            <ul>
                                                                <li><b>. Yoga:</b> Practicing yoga helps in detoxifying the body and mind. It also promotes relaxation and reduces anxiety and stress, which are common issues in addiction recovery.</li>
                                                                <li><b>. Meditation and Mindfulness:</b> Meditation and mindfulness techniques are incorporated to help patients focus, reduce stress, and increase mental clarity, all of which support the recovery process.</li>
                                                            </ul>
                                                            <h4>6. Behavioral Therapy</h4>
                                                            <ul>
                                                                <li><b>. Dialectical Behavioral Therapy (DBT):</b>  DBT focuses on helping patients regulate emotions, cope with stress, and improve interpersonal relationships, which can be crucial for long-term recovery.</li>
                                                                <li><b>. Acceptance and Commitment Therapy (ACT):</b> ACT is used to help individuals accept difficult feelings without being overwhelmed by them and to commit to positive behavioral changes.</li>
                                                            </ul>
                                                            <h4>7. Holistic Therapies</h4>
                                                            <ul>
                                                                <li><b>. Art Therapy:</b> Some centers include creative therapies like art or music therapy to help individuals express emotions they might have difficulty verbalizing, offering therapeutic outlets for healing.</li>
                                                                <li><b>. Aromatherapy and Acupuncture:</b> These alternative therapies can be part of the treatment plan to help reduce stress, alleviate withdrawal symptoms, and promote physical and emotional well-being.</li>
                                                            </ul>
                                                            <h4>8. Relapse Prevention Programs</h4>
                                                            <ul>
                                                                <li><b>. Relapse Prevention Therapy (RPT):</b> A significant part of addiction treatment, these programs focus on equipping individuals with the skills to prevent relapse. They include teaching coping mechanisms, recognizing triggers, and developing long-term strategies for maintaining sobriety.</li>
                                                            </ul>
                                                            <h4>9. Aftercare and Follow-up Programs</h4>
                                                            <ul>
                                                                <li><b>. Aftercare Services:</b> Recovery doesn’t end once the person leaves the center. Aftercare programs offer continued support through follow-up therapy, support groups, and access to ongoing counseling to help prevent relapse.</li>
                                                                <li><b>. 12-Step Programs:</b> Many rehab centers offer access to 12-step programs like Alcoholics Anonymous (AA) or Narcotics Anonymous (NA), which provide community support for individuals in recovery.</li>
                                                            </ul>
                                                            <h4>10. Nutritional and Physical Health Support</h4>
                                                            <ul>
                                                                <li><b>. Nutrition Counseling:</b> Addiction can lead to poor eating habits and physical health issues. Nutritional counseling and healthy eating plans may be provided to support overall well-being and recovery.</li>
                                                                <li><b>. Physical Exercise:</b> Regular physical activities like walking, exercise, and fitness routines help improve physical health, increase endorphins (which naturally improve mood), and aid in the recovery process.</li>
                                                            </ul>
                                                            <h4>11. Spiritual Support</h4>
                                                            <ul>
                                                                <li><b>.</b> Some patients benefit from spiritual counseling, which may involve faith-based recovery programs or meditation practices tailored to the individual's beliefs, helping them find meaning, hope, and purpose in their recovery journey.</li>
                                                            </ul>
                                                        </li>
                                                    </ol>
                                                    <Certifications data={landingPageData.Therapy} />


                                                    <div className="tz">
                                                        <div className="tz-2 top-ms">

                                                            <div className="tz-2-com tz-2-main tz2">
                                                                <h4> Our other Treatment Programs in Patna </h4>
                                                                <Brandlink />
                                                            </div>

                                                            <FaqCommon />

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </div>
        </>
    )
}
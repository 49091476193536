import React from 'react'

var d = new Date();
var currYear = d.getFullYear();
export const Footer = (props) => {

  return (
    <>
      <div id='footer'>
        <div className='container '>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <img style={{ height: '60px', overflow: 'hidden' }} align="left" src="img/sarlo.png" alt='logo'/>
              <div>
                <p style={{ marginTop: '80px' }}>Best Drug De-addiction & Alcohol Rehabilitation Centre in Patna offering best of the Deaddiction Treatment Programs in Patna, Bihar.</p>
              </div>
            <div className="about-social-icon text-center">
              <div>
              <div class="flex-container">
              <div className='facebook'>
                <a title="share with facebook " className="facebook pop-upper without-counter" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsarthakjeevannasamuktikendra.in%2F&amp;t=sarthakjeevannasamuktikendra" target="_blank" rel="noopener noreferrer" >
                <i class="fa fa-facebook"></i>
                  
                </a>
              </div>
              <div className='linkedin'>
                <a title="share with: linkedin" className="linkedin pop-upper without-counter" href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fsarthakjeevannasamuktikendra.in%2F&amp;title=sarthakjeevannasamuktikendra&amp;summary=&amp;source=" target="_blank" rel="noopener noreferrer" style={{ width: "16.6667%" }}>
                <i class="fa fa-linkedin"></i>
                </a>
              </div>
              <div className='instagram'>
                <a title="share with: Instagram " className="instagram pop-upper without-counter" href="https://www.instagram.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fsarthakjeevannasamuktikendra.in%2F&amp;title=sarthakjeevannasamuktikendra&amp;summary=&amp;source=" target="_blank" rel="noopener noreferrer" style={{ width: "16.6667%" }}>
                <i class="fa fa-instagram"></i>
                </a>
              </div>
              <div className='twitter'>
                <a title="tweet " className="twitter pop-upper without-counter" href="https://twitter.com/intent/tweet?text=sarthakjeevannasamuktikendra%20https%3A%2F%2Fsarthakjeevannasamuktikendra.in%2F" target="_blank" rel="noopener noreferrer" style={{ width: "16.6667%" }}>
                <i class="fa fa-twitter"></i>
                </a>
              </div>
              <div className='whatsapp2'>
                <a title="share with: whatsapp" className="whatsapp2 pop-upper without-counter" href="https://wa.me/?text=https://sarthakjeevannasamuktikendra.in." target="_blank" rel="noopener noreferrer" style={{ width: "16.6667%" }}>
                <i class="fa fa-whatsapp"></i>
                </a>
              </div>
                </div>
              </div>
            </div> 
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <h3>Quick Links</h3>
              <ul className='contact-item'>
                <li>
                  <a href='/' className='page-scroll'>
                    Home
                  </a>
                </li>
                <li>
                  <a href='/#about' className='page-scroll'>
                    About Us
                  </a>
                </li>
                <li>
                  <a href='/#features' className='page-scroll'>
                    What We Do
                  </a>
                </li>
                <li>
                  <a href='/#team' className='page-scroll' >
                    Gallrey
                  </a>
                </li>
                <li>
                  <a href='/#services' className='page-scroll'>
                    Services
                  </a>
                </li>
                <li>
                  <a href='/#contact' className='page-scroll'>
                    Contact
                  </a>
                </li>
                <li>
                  <a href='/#testimonials' className='page-scroll'>
                    Testimonials
                  </a>
                </li>
                <li>
                  <a href='/privacy-policy' className='page-scroll'>
                    Privacy Policies
                  </a>
                </li>
              </ul>

            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <h3>Our Programs</h3>
              <ul className='contact-item'>
                <li><a href="/alcohol-addiction-treatment-patna">Alcohol Addiction Treatment</a></li>
                <li><a href="/cocaine-addiction-treatment-patna">Cocaine Addiction Treatment</a></li>
                <li><a href="/drug-addiction-treatment-patna">Drug Addiction Treatment</a></li>
                <li><a href="/heroin-addiction-treatment-patna">Heroin Addiction Treatment</a></li>
                <li><a href="/marijuana-addiction-treatment-patna">Marijuana Addiction Treatment</a></li>
                <li><a href="/whitener-addiction-treatment-patna">Whitener Addiction Treatment</a></li>
                <li><a href="/brownsugar-addiction-treatment-patna">Brown sugar addiction treatment</a></li>
                <li><a href="/injection-addiction-treatment-patna">Injection Addiction treatment</a></li>
                <li><a href="/tablet-addiction-treatment-patna">Tablets addiction treatment</a></li>
              </ul>

            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3600.171417127544!2d85.1851628!3d25.5326664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f2a700063f284f%3A0xa858085eac4dc112!2sSarthak%20jeevan%20nasa%20mukti%20kendra%20*21*21%20Best%20rehabilitation%20centre!5e0!3m2!1sen!2sin!4v1734683417261!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{width:"100%",height:"250px"}}></iframe>

            </div>
          </div>
        </div>
        <div className="row" >
        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
       
        <div className='contact-item' style={{paddingLeft:'50px'}}>
                <p>
                  <span>
                    <h3><i className='fa fa-phone'></i> Phone:</h3>
                  </span>{' '}
                  <a href="tel: 9044605877">
                    {props.data ? props.data.phone : 'loading'}</a>
                </p>
              </div>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        
        <div className='contact-item'>
                <p>
                  <span>
                    <h3><i className='fa fa-envelope-o'></i> Email:</h3>
                  </span>{' '}
                  <a href="mailto: sarthakjeevannasamuktikendra@gmail.com">
                    {props.data ? props.data.email : 'loading'}</a>
                </p>
              </div>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        
        <div className='contact-item'>
                <p>
                  <span>
                    <h3><i className='fa fa-whatsapp'></i> Whatsapp:</h3>
                  </span>{' '}
                  <a href="https://api.whatsapp.com/send?phone=919044605877" target="_blank" rel="noreferrer">
                    {props.data ? props.data.phone : 'loading'}</a>
                </p>
              </div>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        
        <div className='contact-item'>
                <p>
                  <span>
                    <h3><i className='fa fa-map-marker'></i> Location:</h3>
                  </span>{' '}
                  {props.data ? props.data.address : 'loading'}
                </p>
              </div>
        </div>
        </div>
      </div>
      <div className="cpyrit">

        <div className="cpyiner">
          <div className="et_pb_column et_pb_column_4_4 et_pb_column_4_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">

            <div className="et_pb_module et_pb_text et_pb_text_4_tb_footer ftr-btm-cont  et_pb_text_align_left et_pb_bg_layout_light">

              <div className="designli">©{currYear} Copyright&nbsp;By –  Sarthak Jeevan Nasha Mukti Kendra. All rights reserved.| <a href='/privacy-policy' >Privicy Policies</a>
            <span>Designed &amp; Developed by <a href="https://wappotix.com/" target="_blank" style={{ color: "#5adaff" }}> wappotix.com</a></span></div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}


export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 aboutimage">
            {" "}
            <img src="img/about.jpeg" className="img-responsive" alt="Aboutus" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Best Nasha Mukti Kendra in Patna</h2>
              <ol>
              {props.data
                ? props.data.paragraph.map((d, i) => (
                    <p key={`${d}-${i}`}>{d}</p>
                  ))
                : "loading"}
              </ol>

              
            </div>
          </div>
          <div>
          <p className="about-text">Additionally, our Patna Nasha Mukti Kendra has expanded significantly in response to the rising number of addiction cases in the community. We adopt a holistic approach to support addicts in regaining their normal lives as quickly as possible. Unlike traditional hospitals, we treat our patients like family, providing them with love and dedicated care. Through regular counseling and therapy sessions, our hard work has established us as the premier Nasha Mukti Kendra in Patna. If you have a friend or relative struggling with addiction, our rehabilitation center in Patna is the ideal solution to help them reclaim their well-being and happiness.</p>
          <a
            href="/aboutus-detail"
            className='btn btn-customcolor btn-lg page-scroll'
          >
            KNOW MORE
          </a>{' '}
        </div>
        </div>
      </div>
    </div>
  );
};

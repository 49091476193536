import emailjs from 'emailjs-com'
import React, { useState, useRef } from 'react';

export const FaqCommon = (props) => {
    return (
        <div className="tz-2-com tz-2-main tz4">
            <h4> Frequently Asked Questions</h4>
            <div className="col-md-12 main4">
                <div className="demo">
                    <div>

                        <div className="panel-group" id="accordion">

                            <div className="card panel panel-default">
                                <div className="card-header" id="headingOne">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Tell me about Sarthak Jeevan Nasa Mukti Kendra in Patna?
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div className="panel-body" >
                                        <a href="/">Sartak Jeevan Nasa Mukti Kendra</a> in Patna is a specialized rehabilitation center dedicated to helping individuals overcome the challenges of addiction, particularly from narcotics and alcohol. The center provides comprehensive treatment programs, including counseling, therapy, and support, to help individuals lead a healthier, addiction-free life.
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Who can benefit from the services at Sarthak Jeevan Nasa Mukti Kendra?
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                    <div className="panel-body">
                                        <a href="/">Sartak Jeevan Nasa Mukti Kendra</a> is designed for anyone struggling with substance abuse, including alcohol, drugs, and other harmful substances. Whether you are seeking help for yourself or a loved one, our center offers personalized care to those who are committed to overcoming addiction.
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingThree">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i>  What treatment options are available at Sarthak Jeevan Nasa Mukti Kendra?
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                    <div className="panel-body">
                                        <a href="/">Sartak Jeevan Nasa Mukti Kendra</a> offers a range of treatment options, including:
                                        <ul>
                                            <li><b>. Detoxification Programs:</b> Safe withdrawal from substances under medical supervision.</li>
                                            <li><b>. Individual Counseling:</b> One-on-one therapy to address psychological factors behind addiction. </li>
                                            <li><b>. Group Therapy:</b> Peer support groups to share experiences and build connections.</li>
                                            <li><b>. Family Counseling:</b> Programs aimed at educating and involving family members in the recovery process.</li>
                                            <li><b>. Post-recovery Support:</b> Follow-up programs to prevent relapse and ensure long-term success.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingOne">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> How long does the treatment program last?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseFour" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                    <div className="panel-body">
                                        The duration of the treatment program depends on the individual’s specific needs and progress. Typically, the detoxification phase lasts 7-10 days, followed by rehabilitation, which may last from 1 to 6 months. Aftercare programs continue to support recovery long-term.
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingFive">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i>Are the treatment programs confidential?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                    <div className="panel-body">
                                        Yes, At <a href='/'>Sartak Jeevan Nasa Mukti Kendra</a> we prioritize patient confidentiality. All treatments, counseling, and personal information are kept strictly private. We follow strict ethical guidelines to ensure that your journey to recovery is safe and respected.
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingSix">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Is Nasa Mukti Kendra affiliated with any hospitals or health institutions ?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                    <div className="panel-body">
                                        Yes, <a href='/'>Sartak Jeevan Nasa Mukti Kendra</a> works closely with healthcare professionals and hospitals to ensure that our patients receive the best possible care. We collaborate with experienced doctors, psychiatrists, and addiction specialists to offer a well-rounded treatment plan.
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingSeven">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> How can I get admitted to Sarthak Jeevan Nasa Mukti Kendra ?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                                    <div className="panel-body">
                                        Admission to <a href='/'>Sartak Jeevan Nasa Mukti Kendra</a> can be done by contacting us via phone or through our website. You can also visit our center in Patna to discuss the treatment options available. Our team will guide you through the admission process and tailor a treatment plan based on the individual’s needs.
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingEight">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> What are the costs of treatment at Sarthak Jeevan Nasa Mukti Kendra ?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                                    <div className="panel-body">
                                        The cost of treatment varies depending on the type of care and duration of the program. We offer affordable treatment plans and provide flexible payment options. For more details, please contact us directly to get a personalized quote based on your specific needs.
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingNine">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i>  How can family members support a loved one undergoing treatment ?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordion">
                                    <div className="panel-body">
                                        Family involvement is key to the recovery process. We offer family counseling sessions to help relatives understand addiction and provide emotional support. Educating family members about addiction and recovery can create a nurturing environment for the individual and improve the chances of successful rehabilitation.
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingTen">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> What happens if I or my loved one relapses after treatment ?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#accordion">
                                    <div className="panel-body">
                                        Relapse can be a part of the recovery journey, but it doesn’t mean failure. At <a href='/'>Sartak Jeevan Nasa Mukti Kendra</a>, we offer ongoing support and counseling to help individuals get back on track. We have a strong aftercare program and support groups to help prevent relapse and encourage long-term recovery.
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingEleven">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Can I visit Sarthak Jeevan Nasa Mukti Kendra for a consultation before deciding on treatment ?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseEleven" className="collapse" aria-labelledby="headingEleven" data-parent="#accordion">
                                    <div className="panel-body">
                                        Yes, you can schedule a consultation at <a href='/'>Sartak Jeevan Nasa Mukti Kendra</a> to discuss your situation with our medical and counseling team. We are happy to answer any questions you may have and guide you through the process to make an informed decision about treatment.
                                    </div>
                                </div>
                            </div>
                            <div className="card panel panel-default">
                                <div className="card-header" id="headingTwelve">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="true" aria-controls="collapseTwelve">
                                            <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> How do I contact Nasa Mukti Kendra for more information ?
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseTwelve" className="collapse" aria-labelledby="headingTwelve" data-parent="#accordion">
                                    <div className="panel-body">
                                        You can contact us via phone at <a href="tel:9044605877">+91-9044605877</a>  or visit our center at <a href='https://maps.app.goo.gl/awhtbY6fTRawUqZf8' target='blank'>Itta Factory, Sampatchak, Thana - Gopalpura, Bariya, Patna- 800007</a> in Patna. For more information, feel free to reach out through our website's contact form, and we will get back to you promptly.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
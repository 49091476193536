import { useState, useEffect } from "react";
import JsonData from "../../data/data.json";
import { Contact } from "../contact";

export const AboutDetail = (props) => {
  const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);
    
  return (
    <div id="aboutdetail">
      <title>Nasa Mukti Kendra in Patna @9471414102 |  De-Addiction & Rehabilitation Center</title>
      <header id='header'>
        <div className='intro'>
          <div className='overlay'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8 col-md-offset-2 intro-about'>


                  <h2>About Us</h2>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="container">
        <div className="policytext">
          <h3>Welcome To</h3>
          <div>
            <h2>
              Sarthak Jeevan Nasamukti Kendra
            </h2>
          </div>
          <div>
            <p>At <a href="/">Sarthak Jeevan Nasha Mukti Kendra</a>, we are dedicated to transforming lives by helping individuals break free from the grip of addiction and rediscover a life of purpose, health, and happiness. Our center is a haven for those seeking recovery, offering compassionate care, professional support, and proven treatment approaches.</p>
          </div>
          <div>
            <h3>Why Choose Us?</h3>
            <ol>
              <li><p><b><strong>1. Expert Team:</strong></b> Our staff includes experienced doctors, therapists, and counselors who specialize in addiction recovery.</p></li>
              <li><p><b><strong>2. Holistic Approach:</strong></b> We combine medical treatment, counseling, yoga, meditation, and personalized therapies to heal the mind, body, and soul.</p></li>
              <li><p><b><strong>3. Personalized Care:</strong></b> Each recovery journey is unique. We tailor our treatment plans to meet the specific needs of every individual.</p></li>
              <li><p><b><strong>4. Safe & Supportive Environment:</strong></b> We provide a peaceful and judgment-free space where individuals can focus on their recovery.</p></li>
              <li><p><b><strong>5. Aftercare Support:</strong></b> Recovery doesn't end after treatment. We offer continuous follow-up and support to help prevent relapse.</p></li>
            </ol>
          </div>

          <div>
            <h3>Our Programs</h3>
            <ol>
              <li><p><b><strong>. Detoxification:</strong></b> Safe and medically supervised detox to remove harmful substances from the body.</p></li>
              <li><p><b><strong>. Counseling & Therapy:</strong></b> Individual and group therapy sessions to address emotional, psychological, and social aspects of addiction.</p></li>
              <li><p><b><strong>. Rehabilitation:</strong></b> Comprehensive residential programs with 24/7 care and support.</p></li>
              <li><p><b><strong>. Holistic Healing:</strong></b>  Incorporation of yoga, meditation, and recreational activities to promote overall wellness.</p></li>
            </ol>
          </div>
          <div>
            <h3>Our Promise</h3>
            <p>At <a href="/">Sarthak Jeevan Nasha Mukti Kendra</a>, we believe that every individual deserves a second chance. With our commitment, expertise, and compassion, we walk with you every step of the way toward a brighter and addiction-free future.</p>
          </div>
        </div>
      </div>
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

import React from 'react';

// Sample JSON data
const jsonData = [
    { id: 1, name: 'Acohol Addiction Treatment', url: '/alcohol-addiction-treatment-patna' },
    { id: 2, name: 'Cocaine Addiction Treatment', url: '/cocaine-addiction-treatment-patna' },
    { id: 3, name: 'Drug Addiction Treatment', url: '/drug-addiction-treatment-patna' },
    { id: 4, name: 'Heroin Addiction Treatment', url: '/heroin-addiction-treatment-patna' },
    { id: 5, name: 'Marijuana Addiction Treatment', url: '/marijuana-addiction-treatment-patna' },
    { id: 6, name: 'Whitener Addiction Treatment', url: '/whitener-addiction-treatment-patna' },
    { id: 7, name: 'Brown sugar addiction treatment', url: '/brownsugar-addiction-treatment-patna' },
    { id: 8, name: 'Injection Addiction treatment', url: '/injection-addiction-treatment-patna' },
    { id: 9, name: 'Tablets addiction treatment', url: '/tablet-addiction-treatment-patna' },
    

  ];
  
export const Brandlink = () => {
    // Get current page URL path
  const currentPage = window.location.pathname;
    // Filter out the current page
    const filteredData = jsonData.filter((item) => item.url !== currentPage);
    
    return (
    <div className="main2 pregrahd">
    <div className='row'>
    {filteredData.map((item) => (
        <p key={item.id} className='col-md-3 col-sm-6 col-xs-12'>
          <a href={item.url}>{item.name}</a>
        </p>
      ))}   
        
    </div>
    </div>
)
}
